import React, { useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import Slide from "react-reveal/Slide";
import Loader from "../components/loader";

import "../styles/index.sass";

const HeroBanner = styled.section`
  background-image: url(${({ background }) => background});
  background-size: cover;
  height: 100vh;
  background-attachment: fixed;
  display: grid;
  align-items: center;
  @media (max-width: 1000px) {
    background-position: center center;
    background-size: contain;
    height: 180px;
    background-repeat: no-repeat;
    background-attachment: initial;
    margin-top: 120px;
  }
  h1 {
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 65px;
    font-weight: 700;
    letter-spacing: 3px;
    @media (max-width: 600px) {
      font-size: 35px;
    }
  }
`;

const Nav = styled.nav`
  background: #ffffffbf;
  position: fixed;
  width: 100%;
  top: 0;
  button {
    list-style-type: none;
    display: inline;
    color: #000000;
    text-transform: uppercase;
    -webkit-letter-spacing: 5px;
    -moz-letter-spacing: 5px;
    -ms-letter-spacing: 5px;
    letter-spacing: 5px;
    font-size: 15px;
    font-weight: 900;
    background: none;
    border: none;
    font-family: "Karla";
    padding: 0;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: 0;
    }
  }
  a {
    height: 75px;
  }
  z-index: 10000;
  border-bottom: 3px solid black;
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  transition: all 1.5s ease;
  &.white {
    background: #ffffff;
    transition: all 0.5s ease;
  }
  @media (max-width: 1350px) {
    display: block;
  }
  @media (max-width: 475px) {
    display: grid;
    grid-template-columns: 75px 1fr 75px;
    align-items: flex-start;
  }
  @media (max-width: 475px) {
    grid-template-columns: 75px 1fr 75px;
  }
  ul {
    text-align: right;
    &.main-nav {
      li {
        position: relative;
        padding: 15px;
        a {
          list-style-type: none;
          display: inline;
          color: #000000;
          text-transform: uppercase;
          -webkit-letter-spacing: 5px;
          -moz-letter-spacing: 5px;
          -ms-letter-spacing: 5px;
          letter-spacing: 5px;
          font-size: 15px;
          font-weight: 900;
          background: none;
          border: none;
        }
        span {
          cursor: pointer;
        }
      }
      @media (max-width: 1350px) {
        position: absolute;
        top: 163px;
        width: 100%;
        background: #ffffff;
        height: 100vh;
        left: -2000px;
        display: block;
        transition: left 0.5s ease;
        @media (max-width: 600px) {
          top: 155px;
        }
        @media (max-width: 475px) {
          top: 106px;
        }
        &.show {
          left: 0;
          padding: 50px 0 0 0;
          transition: left 0.5s ease;
          li {
            display: list-item;
            text-align: center;
          }
        }
        &.hide {
          left: -2000px;
          transition: left 0.5s ease;
          padding: 50px 0 0 0;
          li {
            display: list-item;
            text-align: center;
          }
        }
      }
    }
    li {
      list-style-type: none;
      display: inline;
      color: #000000;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 15px;
      font-weight: 900;
      a {
        color: #000000;
        text-decoration: none;
      }
    }
  }
`;

const EmailPhone = styled.ul`
  grid-column-start: 2;
  grid-column-end: 3;

  @media (max-width: 475px) {
    span {
      display: grid;
      grid-template-columns: 50px 50px;
      margin: 0 auto;
      justify-items: center;
    }
    &.mob-phone {
      text-align: center;
      padding: 0;
      li {
        display: list-item;
        &.facebook {
          display: none;
        }
        @media (max-width: 475px) {
          font-size: 12px;
          letter-spacing: 3px;
          padding: 15px 0 15px 0;
        }
        a {
          background: #54b554;
          padding: 10px;
          width: 100px;
          border-radius: 23px;
          color: #ffffff;
        }
      }
    }
  }
  li {
    padding: 0 15px 0 15px;
    &.lighter {
      font-weight: 100;
    }
  }
`;

const Hamburger = styled.span`
  padding: 35px 50px 0 0;
  font-size: 22px;
  @media (max-width: 475px) {
    padding: 10px 10px 0px 0;
  }
  svg {
    height: 2em;
    width: 2em !important;
    @media (max-width: 475px) {
      height: 1.75em;
    }
  }
`;

const ImageLogo = styled.img`
  width: 75px;
  display: block;
  padding: 25px;
  @media (max-width: 1200px) {
    &.active-ham {
    }
  }
  @media (max-width: 600px) {
    width: 50px;
    padding: 10px;
  }
`;

const Footer = styled.div`
  background: grey;
  padding: 100px;
  text-align: center;
  display: grid;
  justify-items: center;
  @media (max-width: 650px) {
    padding: 0;
  }
  h4 {
    font-size: 20px;
    font-weight: 100;
    letter-spacing: 2px;
    color: #ffffff;
    width: 625px;
    line-height: 32px;
    padding: 0 0 50px 0;
    @media (max-width: 650px) {
      width: 90%;
    }
  }
`;

// Do not delete below

// const MapWrapper = styled.div`
//   width: 600px;
//   padding: 0 0 50px 0;
//   height: 450px;
//   @media (max-width: 1200px) {
//     width: 100%;
//   }
//   .g-map {
//   }
// `;

// DO not delete above

const CheckBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  label {
    display: grid;
    justify-items: center;
    text-align: center;
  }
  h4 {
    margin-top: 0;
    margin-bottom: 10px;
    color: #777777;
  }
  .pretty {
    margin-right: 0;
    font-size: 32px;
  }
  .pretty .state label:before {
    background: #ffffff;
  }
`;

const HomeForm = styled.form`
  background: lightgrey;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  justify-items: center;
  padding: 75px;
  //width: 90%;
  //box-sizing: border-box;
  #contact {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  @media (max-width: 750px) {
    display: block;
    padding: 0;
    padding: 30px 0;
  }
  h3 {
    span {
      color: red;
    }
  }
  label {
    width: 100%;
    @media (max-width: 750px) {
      width: 90%;
      margin: 0 auto;
      display: block;
    }
  }
  .checky {
    width: 100%;
  }
  input[type="text"],
  input[type="email"] {
    padding: 12px 12px;
    font-size: 20px;
    border: none;
    box-sizing: border-box;
    width: 100%;
    border-radius: 20px;
    &:focus {
      outline: 0;
    }
    @media (max-width: 750px) {
      width: 90%;
      margin: 0 auto;
      display: block;
    }
  }
  .text-area {
    grid-column-start: 1;
    grid-column-end: 3;
    width: 100%;
    textarea {
      height: 150px;
      border: none;
      box-sizing: border-box;
      width: 100%;
      border-radius: 20px;
      border: none;
      padding: 12px 12px;
      font-size: 20px;
      border: none;
      box-sizing: border-box;
      &:focus {
        outline: 0;
      }
    }
  }
  .submitHand {
    grid-column-start: 1;
    grid-column-end: 3;
    button {
      width: 100%;
      margin-top: 25px;
      border: none;
      background: green;
      color: #ffffff;
      padding: 20px;
      font-size: 32px;
      border-radius: 20px;
    }
    &.fail {
      a,
      span {
        display: block;
        text-align: center;
      }
      button {
        background: grey;
      }
    }
  }
`;

const ThankYou = styled.div`
  margin: 0 auto;
  width: 50%;
  text-align: center;
  padding: 150px;
`;

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #adadad;
  @media (max-width: 1350px) {
    grid-template-columns: 1fr;
  }
  h2 {
    color: #ffffff;
    align-self: center;
    font-size: 40px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
`;

const Header = styled.h2`
  text-align: center;
`;

const ScrollDown = styled.div`
  position: absolute;
  bottom: 30px;
  font-size: 42px;
  color: #000;
  text-align: center;
  width: 75px;
  height: 75px;
  font-weight: 900;
  border-radius: 50%;
  left: 50%;
  margin-left: -25px;
  display: grid;
  align-items: center;
  transform: rotateX(180deg);
  background: #fff;
`;

// *Scroll Function*

const isBrowser = typeof window !== `undefined`;

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

// *Scroll Function Ends*

// *hook*

export function useScrollPosition(effect, deps, element, useWindow, wait) {
  const position = useRef(getScrollPosition({ useWindow }));

  let throttleTimeout = null;

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow });
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, deps);
}

// *hook ends*

const TemplateWrapper = ({ children }) => {
  // *running function*

  const [hideOnScroll, setHideOnScroll] = useState(false);
  const shadowStyle = hideOnScroll ? "white" : "";

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y < -100;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  //hamburger menu

  const [state, setState] = useState(false);

  function toggle() {
    setState(!state);
  }

  const [formState, setFormState] = React.useState({
    name: "",
    package: "",
    email: "",
    subject: "",
    message: "",
    reasons: {
      weightLoss: false,
      strength: false,
      sport: false,
    },
  });

  const formStateCheck = formState;

  const checkValidationName = () => {
    if (formStateCheck.name === "") {
      setShowMessageName(true);
    } else {
      setShowMessageName(false);
    }
  };

  const checkValidationEmail = () => {
    if (formStateCheck.email === "") {
      setShowMessageEmail(true);
    } else {
      setShowMessageEmail(false);
    }
  };

  const checkValidationSubject = () => {
    if (formStateCheck.subject === "") {
      setShowMessageSubject(true);
    } else {
      setShowMessageSubject(false);
    }
  };

  const checkValidationMessage = () => {
    if (formStateCheck.message === "") {
      setShowMessageMessage(true);
    } else {
      setShowMessageMessage(false);
    }
  };

  const checkValidationSubmit = () => {
    const formStateCheck = formState;

    if (
      formStateCheck.name !== "" &&
      formStateCheck.email !== "" &&
      formStateCheck.subject !== "" &&
      formStateCheck.message !== ""
    ) {
      setFormInvalid(true);
    }
  };

  const onChange = (e) => {
    if (e.target.type === "checkbox") {
      const changedReason = e.target.getAttribute("name");
      setFormState({
        ...formState,
        reasons: {
          ...formState.reasons,
          [changedReason]: !formState.reasons[changedReason],
        },
      });
      console.log(formState);
    } else {
      setFormState({ ...formState, [e.target.name]: e.target.value });
    }

    checkValidationSubmit();
  };

  const prepareDataForApi = (formData) => {
    const newFormData = Object.assign({}, formData); // optional if passed cloned copy of formData object or you can also use lodash cloneDeep
    newFormData.data = {
      reasonArray: [],
    };

    Object.keys(newFormData.reasons).forEach((key) => {
      if (newFormData.reasons[key]) {
        newFormData.data.reasonArray.push({ reason: key });
      }
    });

    delete newFormData.reasons;

    return newFormData;
  };

  //Form Errors and Hiding and Showing

  const [form, setForm] = useState(true);

  function showForm(e) {
    e.preventDefault();
    setForm(true);
  }

  const [formFail, setFormFail] = useState(true);

  function formFailReset(e) {
    e.preventDefault();
    setFormFail(true);
  }

  const [formInvalid, setFormInvalid] = useState(false);

  // const [nameVal, setNameVal] = useState(false);

  const [showMessageName, setShowMessageName] = useState(false);

  const [showMessageEmail, setShowMessageEmail] = useState(false);

  const [showMessageSubject, setShowMessageSubject] = useState(false);

  const [showMessageMessage, setShowMessageMessage] = useState(false);

  const [loading, setLoading] = useState(true);

  //End

  // Try Catch

  //End

  const submitForm = async (e) => {
    e.preventDefault();

    //All validation
    if (
      formState.name === "" ||
      formState.email === "" ||
      formState.subject === "" ||
      formState.message === ""
    ) {
      setFormInvalid(false);
    } else {
      setFormInvalid(true);

      const newFormData = prepareDataForApi(formState);

      setLoading(false);
      setForm(false);

      try {
        const response = await fetch("/.netlify/functions/sendmail", {
          method: "POST",
          body: JSON.stringify(newFormData),
        }).then(() => {
          setLoading(true);
        });

        if (!response.ok) {
          console.log(response);

          setFormFail(false);

          return;
        }

        console.log("success email");
      } catch (e) {
        console.log("error");
      }
    }
  };

  //**********FORM FUNCTION ENDS*****/

  return (
    <StaticQuery
      query={graphql`
        query LayoutQueryAbout {
          datoCmsSite {
            globalSeo {
              siteName
            }
            faviconMetaTags {
              ...GatsbyDatoCmsFaviconMetaTags
            }
          }
          datoCmsAbout {
            contactMeHeader
            heroImage {
              url
            }
          }
          datoCmsHome {
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
            introHeader
            copyright
            legalCopy
            mapHeader
            homeHeroImage {
              url
              title
            }
            topGradientColor {
              rgb
              hex
            }
            bottomGradientColor {
              rgb
              hex
            }
          }
          datoCmsHeader {
            logo {
              url
            }
          }
          datoCmsSocialProfile {
            facebookUrl
            email
            phoneNumber
          }
          datoCmsAvailability {
            title
          }
        }
      `}
      render={(data) => (
        <div className="container">
          <HelmetDatoCms
            favicon={data.datoCmsSite.faviconMetaTags}
            seo={data.datoCmsHome.seoMetaTags}
            title="test"
          />

          <Nav className={`header ${shadowStyle}`}>
            <Link to="/">
              <ImageLogo
                className={state ? `active-ham` : ``}
                src={data.datoCmsHeader.logo.url}
              />
            </Link>
            <ul className={state ? `main-nav show` : `main-nav hide`}>
              <li>
                <Link to="/#pricing">Individual Pricing</Link>
              </li>
              <li>
                <Link to="/#groupPricing">Group Pricing</Link>
              </li>

              <li>
                <Link to="/#testimonials">Testimonials</Link>
              </li>
              <li>
                <Link to="/#about">About</Link>
              </li>
              <li>
                <Link to="/availability">Availability</Link>
              </li>
              <li>
                <Link to="/#contact">Contact</Link>
              </li>
            </ul>

            <EmailPhone
              className={state ? `mob-phone active-ham` : `mob-phone`}
            >
              <Hamburger onClick={toggle} className="hamTog">
                <FontAwesomeIcon icon={faBars} />
              </Hamburger>

              <li className="lighter">
                <a href={`mailto:` + data.datoCmsSocialProfile.email}>
                  {data.datoCmsSocialProfile.email}
                </a>
              </li>
              <li className="lighter">
                <a href={`tel:` + data.datoCmsSocialProfile.phoneNumber}>
                  {data.datoCmsSocialProfile.phoneNumber}
                </a>
              </li>
              <span>
                <li className="facebook">
                  <a href={data.datoCmsSocialProfile.facebookUrl}>
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </li>
              </span>
            </EmailPhone>
          </Nav>

          <HeroBanner
            background={data.datoCmsAbout.heroImage.url}
            topGradient={data.datoCmsHome.topGradientColor.hex}
            bottomGradient={data.datoCmsHome.bottomGradientColor.hex}
          ></HeroBanner>

          <ScrollDown>^</ScrollDown>

          <div>
            <span>{children}</span>
          </div>

          {/* FORM STARTS HERE */}

          <FormWrapper>
            <Header>{data.datoCmsAbout.contactMeHeader}</Header>

            {form ? (
              <Slide right>
                <HomeForm onSubmit={submitForm} autoComplete="off">
                  {/* <input type="text" name="package" value={data.datoCmsPricing.title} /> */}
                  <label>
                    <h3>Name{showMessageName && <span> *Required</span>}</h3>
                    <input
                      type="text"
                      name="name"
                      value={formState.name}
                      onChange={onChange}
                      onKeyUp={checkValidationName}
                      autoComplete="off"
                    />
                  </label>
                  <label>
                    <h3>Email{showMessageEmail && <span> *Required</span>}</h3>
                    <input
                      type="email"
                      name="email"
                      value={formState.email}
                      onChange={onChange}
                      onKeyUp={checkValidationEmail}
                      autoComplete="off"
                    />
                  </label>
                  <label>
                    <h3>
                      Subject{showMessageSubject && <span> *Required</span>}
                    </h3>
                    <input
                      type="text"
                      name="subject"
                      value={formState.subject}
                      onChange={onChange}
                      onKeyUp={checkValidationSubject}
                    />
                  </label>
                  <div className="checky">
                    <h3>Reasons for wanting to train</h3>
                    <CheckBoxes>
                      <label>
                        <h4>Weight Loss</h4>
                        <div className="pretty p-smooth p-curve p-fill">
                          <input
                            type="checkbox"
                            name="weightLoss"
                            reason="Weight Loss"
                            checked={formState.reasons.weightLoss}
                            onChange={onChange}
                          />
                          <div className="state p-primary">
                            <label></label>
                          </div>
                        </div>
                      </label>
                      <label>
                        <h4>Strength</h4>
                        <div className="pretty p-smooth p-curve p-fill">
                          <input
                            type="checkbox"
                            name="strength"
                            reason="Strength"
                            checked={formState.reasons.strength}
                            onChange={onChange}
                          />
                          <div className="state p-primary">
                            <label></label>
                          </div>
                        </div>
                      </label>
                      <label>
                        <h4>Sport</h4>
                        <div className="pretty p-smooth p-curve p-fill">
                          <input
                            type="checkbox"
                            name="sport"
                            reason="Sport"
                            checked={formState.reasons.sport}
                            onChange={onChange}
                          />
                          <div className="state p-primary">
                            <label></label>
                          </div>
                        </div>
                      </label>
                      <label for="other">
                        <h4>Other</h4>
                        <div className="pretty p-smooth p-curve p-fill">
                          <input
                            type="checkbox"
                            name="other"
                            reason="Other"
                            checked={formState.reasons.other}
                            onChange={onChange}
                          />
                          <div className="state p-primary">
                            <label for="other"></label>
                          </div>
                        </div>
                      </label>
                    </CheckBoxes>
                  </div>

                  <div className="text-area">
                    <label>
                      <h3>
                        Message{showMessageMessage && <span> *Required</span>}
                      </h3>
                      <textarea
                        name="message"
                        value={formState.message}
                        onChange={onChange}
                        onKeyUp={checkValidationMessage}
                      />
                    </label>
                  </div>

                  {formFail ? (
                    <label
                      className={formInvalid ? `submitHand` : `submitHand fail`}
                    >
                      {formInvalid ? (
                        <button type="submit">Submit</button>
                      ) : (
                        <button disabled type="submit">
                          Submit
                        </button>
                      )}
                    </label>
                  ) : (
                    <label className="submitHand fail">
                      <span>Something Went Wrong</span>
                      <a onClick={formFailReset} href="#">
                        Click to reset form
                      </a>
                      <button disabled type="submit">
                        Submit
                      </button>
                    </label>
                  )}
                </HomeForm>
              </Slide>
            ) : (
              <>
                {loading ? (
                  <ThankYou>
                    <div>
                      <p>Thank you your message has been sent</p>
                    </div>

                    <a onClick={showForm} href="#">
                      Send Again
                    </a>
                  </ThankYou>
                ) : (
                  <ThankYou>
                    <Loader />
                  </ThankYou>
                )}
              </>
            )}
          </FormWrapper>

          {/* fORM ENDS HERE */}

          <Footer className="copyright">
            <h4>{data.datoCmsHome.mapHeader}</h4>

            <p>{data.datoCmsHome.copyright}</p>
            <p>{data.datoCmsHome.legalCopy}</p>
          </Footer>
        </div>
      )}
    />
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.object,
};

export default TemplateWrapper;
