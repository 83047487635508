import React from "react";
import { graphql } from "gatsby";
import LayoutAbout from "../components/layoutAbout";
import styled from "styled-components";

const HeaderThree = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 4;
  margin-top: 50px;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 3px;
  h1 {
    font-size: 50px;
    font-weight: 100;
    margin: 0;
  }
  h2 {
    text-align: center;
    margin: 0;
    color: #000000;
    font-weight: 100;
    margin: 25px 0;
    @media (max-width: 600px) {
      font-size: 22px;
    }
  }
  p {
    font-size: 50px;
  }
  @media (max-width: 1500px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  @media (max-width: 960px) {
    grid-column-start: initial;
    grid-column-end: initial;
    font-size: 30px;
  }
  @media (max-width: 500px) {
    font-size: 22px;
  }
`;

const AboutMe = styled.section`
  display: grid;
  justify-items: center;
  margin: 0 auto;
  .intro p {
    font-size: 54px;
    @media (max-width: 960px) {
      font-size: 30px;
    }
    @media (max-width: 500px) {
      font-size: 22px;
    }
  }
  div {
    p {
      font-size: 22px;
      line-height: 38px;
    }
  }
  .profile img {
    //clip-path: circle(75px at center);
    width: 350px;
    @media (max-width: 600px) {
      width: 100%;
      margin-top: 0;
    }
    //object-fit: cover;
    //height: 150px;
    margin-top: 50px;
  }
`;

const Intro = styled.section`
  padding: 0;
  margin: 0 auto;
  width: 90%;
  p.quote {
    width: 45%;
    margin: 0;
    margin: 0 auto;
    text-align: center;
    color: #004f6b;
    padding: 0px 0 50px 0;
    font-size: 22px;
    line-height: 42px;
    font-style: italic;
    text-align: left;
    quotes: "“" "”" "‘" "’";
    border-bottom: 1px solid #000000;
    position: relative;
    @media (max-width: 1300px) {
      width: 55%;
    }
    @media (max-width: 750px) {
      width: 90%;
    }
    &:before {
      content: open-quote;
      font-size: 62px;
      position: absolute;
      transform: translate(-40px, -10px);
    }
    &:after {
      content: close-quote;
      font-size: 62px;
      position: absolute;
      transform: translate(0px, 5px);
    }
  }
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 1200px;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  margin-bottom: 150px;
  @media (max-width: 1350px) {
    width: 80%;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
`;

const Skills = styled.div`
  p {
    text-align: left;
  }
  ul {
    padding: 2%;
    li {
      line-height: 42px;
      font-size: 22px;
      @media (max-width: 960px) {
        font-size: 18px;
        line-height: 38px;
      }
    }
  }
  margin-bottom: 100px;
`;

const SkillsWrapper = styled.section`
  width: 45%;
  h3 {
    font-size: 50px;
    font-weight: 100;
    text-align: center;
  }
  margin: 100px auto 0 auto;
  @media (max-width: 960px) {
    width: 90%;
  }
`;

const About = ({ data }) => {
  return (
    <LayoutAbout>
      <AboutMe>
        <div className="profile">
          {data.datoCmsAbout.profilePicture && (
            <img
              alt={data.datoCmsAbout.profilePicture.alt}
              src={data.datoCmsAbout.profilePicture.url}
            />
          )}
        </div>

        <div>
          <Intro>
            <HeaderThree
              className="intro"
              dangerouslySetInnerHTML={{
                __html: data.datoCmsAbout.aboutMeHeader,
              }}
            />
            <p className="quote">{data.datoCmsAbout.missionStatement}</p>
            <SkillsWrapper>
              <h3>{data.datoCmsAbout.technicalKnowledgeHeader}</h3>
              <Skills
                dangerouslySetInnerHTML={{
                  __html:
                    data.datoCmsAbout.aboutMeNode.childMarkdownRemark.html,
                }}
              />
            </SkillsWrapper>
          </Intro>
        </div>
        {data.datoCmsAbout.hideImageGallery ? null : (
          <Gallery className="details-list">
            {data.datoCmsAbout.imagesOfTraining.map((detailEntry) => {
              return (
                <div key={detailEntry.id}>
                  <img alt={detailEntry.alt} src={detailEntry.url} />
                </div>
              );
            })}
          </Gallery>
        )}
      </AboutMe>
    </LayoutAbout>
  );
};

export default About;

export const query = graphql`
  query AboutQueryPage {
    datoCmsAbout {
      aboutMeHeader
      aboutMeSubHeader
      missionStatement
      hideImageGallery
      aboutMeHeaderNode {
        childMarkdownRemark {
          html
        }
      }
      profilePicture {
        url
        alt
      }
      technicalKnowledgeHeader
      aboutMeNode {
        childMarkdownRemark {
          html
        }
      }
      imagesOfTraining {
        url
        alt
      }
    }
  }
`;
